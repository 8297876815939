import { useDispatch, useSelector } from 'react-redux';
import { React, useState, useEffect, useContext } from 'react';
import { Route, Router, Switch, NavLink, useLocation } from 'react-router-dom';

import { io } from 'socket.io-client';

import { ModalContext } from './context/ModalContext';
import { SocketContext } from './context/SocketContext';
import { WindowContext } from './context/WindowContext';

import HomePage from './components/HomePage';
import {restoreUser} from './redux/middleware/users'

function App() {

  const location = useLocation();
  const dispatch = useDispatch();
  const [loaded, isLoaded] = useState(false);
  const { modal, openModal, closeModal, setUpdateObj } = useContext(ModalContext);
  const user = useSelector((state) => state.users.user);


  






  return (
    <>
      <div>
        <Switch>

          <Route path="/" exact>
            <HomePage />
          </Route>
          
          <Route>
            <HomePage/>
          </Route>

        </Switch>

      </div>
    </>
  );
}

export default App;
