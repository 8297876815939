import React from 'react';
import useForm from '../../hooks/useForm';
import './ContactForm.css';

const ContactForm = () => {
    const { formData, errors, handleInput, isFormValid } = useForm();
  
    return (
      <section id="contact">
        <div>
          <form
            noValidate
            action="https://formspree.io/f/xbjvoykb"
            method="POST"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label>
              <span>Email</span>
              <input
                className={errors._replyto && 'invalid'}
                onChange={handleInput}
                value={formData.email}
                id="email"
                name="_replyto"
                type="email"
                required
                placeholder="example@gmail.com"
              />
            </label>
            <label>
              <span>Name</span>
              <input
                className={errors.name && 'invalid'}
                onChange={handleInput}
                value={formData.name}
                name="name"
                type="text"
                required
                placeholder="John Doe"
              />
            </label>
            <label>
              <span>Message</span>
              <textarea
                className={errors.message && 'invalid'}
                onChange={handleInput}
                value={formData.message}
                name="message"
                required
                placeholder="Hi, I'm from [Company Name]. We have a project that might benefit from your expertise. Can we discuss further?"
              />
            </label>
  
            <button
              disabled={!isFormValid}
              type="submit"
              value="send"
              className='memo'
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    );
  }
  export default ContactForm;
  