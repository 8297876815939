import React, { useState, useRef, useEffect, useContext, createRef } from 'react';
import { Route, Router, Switch, NavLink, Link,useHistory, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './AboutMePage.css'
import profilePic from '../../assets/images/profilepic_donovan.jpeg';
// import aboutmeBg from '../../assets/images/aboutme-bg.png';


const AboutMePage = () => {

const  [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 100);
  }, []);


    return (
      <div className='aboutme-container flex center'>

        
  
        <div className="aboutme-background-container"> 

          <div className='bg layer1'>
            <div></div>

          </div>


          <div className='bg layer2'>
          <div></div>


          </div>

          <div className='bg layer3'>


          </div>
        
        
        </div>
  
  
  
        <div className='aboutme-content flex center'>
          <div className='aboutme-header flex center'>
            <div className='welcome-image flex center'>
{isLoaded &&              <img className='fade-in' src={profilePic} alt='creator picture' ></img>}

            </div>
            <div className='welcome-text'>

              Welcome and thanks for checking out my portfolio. 
            </div>

            
            
            </div>
          <p className='aboutme-content-block memo'>I'm Donovan, a full-stack software engineer based in beautiful San Diego, California. </p>
            <p className='aboutme-content-block memo'>My experiences traveling the world surfing and time within our military have provided a unique perspective, 
            helping me approach each project with a broadened mindset and meticulous attention to detail.</p>
            <p className='aboutme-content-block memo'> I love the challenge of creating solutions the world won't just use, but will truly enjoy and benefit from daily.</p>
          <p className='aboutme-content-block memo'>I appreciate you taking the time to look at my work. If you'd like to talk about a potential project, I'm open to connecting.</p>
        </div>
  
  



      </div>
  
  
  // wd_feed2 wd_home, wd_log
  // wd_create, wd_feed, ss_private, ss_multi_table, ss_friend
  
  
  
    )
  }
  export default AboutMePage
  



// // {/* 
// // <div class="aboutme-background-container">

// // </div>




    

// {/* <div className='aboutme-photoandname-wrapper flex center'> 


//     <div className='aboutme-photoandname flex'> 


//     <div className='aboutme-photo-container flex center'>

//     {isLoaded &&      
//       <div ref={profilePicRef} className='aboutme-photo fade-in'>
//         <img src={profilePic} alt='photo' />
//       </div>
//     }

//     <div ref={skeletonPicRef} className={`aboutme-photo skeleton`}>
//     </div>


//     </div>




//     <div className='aboutme-welcome memo flex center'>

//       <div ref={welcomeGreetingRef} className='aboutme-content memo flex center'>Thanks for checking out my portfolio.</div>
      
//     </div>



    

//     </div>


// </div> */}




// //       <div className='aboutme-bio-container memo flex'> 


// //         <p></p>

      
// //         <p ref={pRefs.current[0]} className='bio-content skinny memo'>I'm Donovan, a full-stack software engineer based in beautiful San Diego, California. </p>
// //           <p ref={pRefs.current[1]} className='bio-content memo'>My experiences traveling the world surfing and time within in our military have provided a unique perspective that I bring to all my endeavors, 
// //           helping me approach each project with a broadened mindset and meticulous attention to detail.</p>
// //           <p ref={pRefs.current[2]} className='bio-content memo'> I love to create solutions the world won't just use, but will truly enjoy and benefit from daily.  </p>
// //         <p ref={pRefs.current[3]} className='bio-content memo'> Interested in discussing a project? Let's connect.</p>
        
     


    

// //       </div> */}