import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Route,
  Router,
  Switch,
  NavLink,
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '../../components/Navigation';
import './HomePage.css';
import { WindowContext } from '../../context/WindowContext';
import SplashPage from '../../components/SplashPage';
import AboutMePage from '../../components/AboutMePage';
import ProjectPage from '../ProjectPage';

import profilePic from '../../assets/images/profilepic_donovan.jpeg';
import homepage from '../../assets/images/homepage.png';
import aboutme from '../../assets/images/aboutme.png';
import ContactPage from '../ContactPage';

import ssHomePage from '../../assets/images/socialStakes/home2.png';
import ssMultiTable from '../../assets/images/socialStakes/play2.png';
import ssChat from '../../assets/images/socialStakes/chat2.png';
import ssPrivate from '../../assets/images/socialStakes/create2.png';
import ssFriend from '../../assets/images/socialStakes/friends2.png';



import wdHomePage from '../../assets/images/wellDone/homepage.png';
import wdFeed from '../../assets/images/wellDone/feed.png';
import wdFeed2 from '../../assets/images/wellDone/personal.png';
import wdCreate from '../../assets/images/wellDone/create.png';
import wdLog from '../../assets/images/wellDone/signup.png';
import wdStory from '../../assets/images/wellDone/reviews.png';



import airHome from '../../assets/images/airbnb/allspots.png';
import airView from '../../assets/images/airbnb/spotview.png';
import airSign from '../../assets/images/airbnb/login.png';
import airReview from '../../assets/images/airbnb/reviews.png';
import airRent from '../../assets/images/airbnb/createspot.png';
import airManage from '../../assets/images/airbnb/manage.png';





const HomePage = () => {
  const {
    windowWidth,
    windowHeight,
    elementIsAtTopOfScreen,
    setElementIsAtTopOfScreen,
  } = useContext(WindowContext);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [view, setView] = useState(false);

  const aboutMeRef = useRef(null);
  const projectsRef = useRef(null);
  // const resumeRef = useRef(null);
  const contactRef = useRef(null);


//  js,py,react,redux,css,html,aws
// nodejs,express,flask,sequelize,sqlite,mysql,postgres
  const projects = {
    socialStakes: {
      name: 'Social Stakes',
      key: 'socialStakes',
      images: [
        {
          description: `Social Stakes provides a platform where friends can gather to play their favorite gambling games. Enjoy the rush of winning and the fun of social gaming all in one place.`,
          title: 'The Social Gaming Central',
          image: ssHomePage,
        },
        {
          description: `Built to optimize user engagement, Social Stakes enables simultaneous participation in up to 6 tables. Leveraging efficient state management and responsive design, users can effortlessly switch between games, maintaining a streamlined and intuitive gameplay interface.`,
          title: 'Efficient Multi-table Management',
          image: ssMultiTable,
        },
        {
          description: `Incorporated a real-time chat system using WebSockets, allowing users to communicate fluidly while gaming. Included message editing and deletion functionalities, enhancing user flexibility.`,
          title: 'Chat & Connect',
          image: ssChat,
        },
        {
          description: `At our platform, we understand the joy of playing games with close friends. That's why we've engineered a module for users to create private gaming tables with customizable settings. This feature, built with user privacy and customization in mind, showcases tailored user experiences and data protection`,
          title: 'Private Gaming Rooms',
          image: ssPrivate,
        },
        {
          description: `Leveraged advanced UX/UI principles to design the 'Add Friend' functionality. Whether it's someone you just played a memorable game with or an old pal, keep them close and game on together!`,
          title: 'Expand & Play Together',
          image: ssFriend,
        },
      ],
      technologies: ['js','react','redux','css','html','aws','flask','postgres'],
      gitHubUrl: 'https://github.com/dcraderdev/SocialStakes',
      liveUrl: 'https://socialstakes.onrender.com/',
      description: [],
    },
    wellDone: {
      name: 'Well Done',
      key: 'wellDone',
      images: [
        {
            description: "Engineered a dynamic story-sharing platform using best-in-class web technologies. The intricate design allows for efficient data handling, optimal server responses, and robust scalability, catering to a diverse user base.",
            title: "Advanced Backend Engineering",
            image: wdHomePage,
        },
        {
            description: "Developed a sophisticated recommendation algorithm, analyzing user follows and preferences. The mechanism enhances platform usability by delivering content that aligns with individual user interests, demonstrating expertise in personalization.",
            title: "Algorithm-Driven Personalized Feeds",
            image: wdFeed,
        },
        {
            description: "Integrating cutting-edge technology, Well-Done employs AWS S3 for effortless and secure image uploads. Every picture you share complements your story, ensuring a captivating reading experience.",
            title: "Seamless Image Uploads with AWS",
            image: wdCreate,
        },
        {
            description: "Adopted Flask-Login and Redux for a multi-layered authentication system. Through cryptographic methods like password hashing and rigorous input validation, we fortified platform integrity.",
            title: "State-of-the-Art Security Protocols",
            image: wdLog,
        },
        {
            description: "Leveraged cutting-edge front-end technologies for rendering content, enabling dynamic parsing of text formats and images. Attention to detail ensured both responsiveness and aesthetic appeal, catering to diverse devices and screen sizes.",
            title: "Advanced Content Rendering Techniques",
            image: wdFeed2,
        },
        {
            description: "Pioneered interactive features, such as claps and follows, enhancing user engagement. Each component was meticulously crafted to ensure optimal performance, seamless UX, and reduced server strain.",
            title: "Optimized User Engagement Features",
            image: wdStory,
        },
    ],
      technologies: ['py','react','redux','css','html','aws','flask','postgres'],
      gitHubUrl: 'https://github.com/isaiahxs/well-done-group-project',
      liveUrl: 'https://well-done.onrender.com/',
    },
    airbnb: {
      name: 'Airbnb',
      key: 'airbnb',
      images: [
        {
            description: "Executed a full-fledged replica of Airbnb, showcasing proficiency in replicating intricate functionalities and database structures inherent to large-scale applications.",
            title: "Meticulous Feature Replication",
            image: airHome,
        },
        {
            description: "Harnessing modern database technologies, effectively managed extensive property listings, with a keen focus on query optimization and ensuring real-time data accuracy and ensuring scalability for potential feature extensions.",
            title: "High-performance Database Queries",
            image: airRent,
        },
        {
            description: "Incorporated a sophisticated user review system, designed to handle large volumes of data efficiently, while ensuring data integrity and consistency across the platform.",
            title: "Scalable Review Mechanisms",
            image: airReview,
        },
        {
            description: "Prioritized best practices in backend development, achieving secure user authentication, intricate error handling, and streamlining backend processes for optimal performance.",
            title: "Backend Excellence",
            image: airSign,
        },
        {
            description: "Proactively addressed security concerns, implementing rigorous protections against potential CORS and CSRF attacks, showcasing a commitment to creating airtight web applications.",
            title: "Advanced Security Implementations",
            image: airManage,
        },
        {
            description: "Implemented a responsive and intuitive user interface, adhering to best UX practices, ensuring seamless interactions while handling extensive real-time data operations.",
            title: "Intuitive UX & Real-time Operations",
            image: airView,
        },
    ],
      technologies: ['js','react','redux','css','html','aws','flask','postgres'],
      gitHubUrl: 'https://github.com/dcraderdev/AirBnB',
      liveUrl: 'https://air-b-n-b-clone.onrender.com/',
    },
  };

  // show nav compenent when splash page nav reaches the top
  useEffect(() => {
    setShowNavbar(false);
    if (elementIsAtTopOfScreen) {
      setShowNavbar(true);
    }
  }, [elementIsAtTopOfScreen]);

  // handle nav highlite depending on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll >= 0.5 * window.innerHeight) {
        setView('aboutme');
      }

      if (currentScroll >= 2 * window.innerHeight) {
        setView('projects');
      }


      let numProjects = Object.keys(projects).length
      if (currentScroll >= (2 + numProjects) * window.innerHeight) {
        setView('contact');
      }



      if (currentScroll < 0.5 * window.innerHeight) {
        setView(null);
      }

      if (window.scrollY * 2 >= window.innerHeight) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // scroll controller
  const scrollTo = (el) => {
    if (el === 'aboutme') {
      scrollToAboutMe();
    }

    if (el === 'projects') {
      scrollToProjects();
    }

    if (el === 'contact') {
      scrollToContact();
    }

    if (el === 'top') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  //handles scroll speed inside scroll handlers
  const smoothScrollTo = (targetY, duration) => {
    const startY = window.scrollY;
    const diff = targetY - startY;
    let start;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const time = timestamp - start;
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  // scroll handlers (called within scrollTo)
  const scrollToAboutMe = () => {
    if (aboutMeRef && aboutMeRef.current) {
      const targetY = aboutMeRef.current.offsetTop;
      smoothScrollTo(targetY, 900); // 1000 ms = 1 second
    }
  };

  const scrollToProjects = () => {
    if (projectsRef && projectsRef.current) {
      const targetY = projectsRef.current.offsetTop;
      smoothScrollTo(targetY, 900); // 1000 ms = 1 second
    }
  };

  const scrollToContact = () => {
    if (contactRef && contactRef.current) {
      const targetY = contactRef.current.offsetTop;
      smoothScrollTo(targetY, 900); // 1000 ms = 1 second
    }
  };

  return (
    <div className="homepage-wrapper flex">
      {showNavbar && (
        <div className="navigation-wrapper">
          <Navigation highlite={view} scrollTo={scrollTo} />
        </div>
      )}

      <div className="splash-wrapper flex">
        <SplashPage scrollTo={scrollTo} />
      </div>

      <div className="spacer"></div>

      <div ref={aboutMeRef} className="aboutme-wrapper flex center">
        <AboutMePage />
      </div>

      <div ref={projectsRef} className="project-wrapper">
        {Object.values(projects).map((projectData) => (
          <ProjectPage key={projectData.key} project={projectData} />
        ))}
      </div>

      <div ref={contactRef} className="contact-wrapper">
          <ContactPage />
      </div>



      {/* {showScrollToTop && (
        <div
          onClick={() => scrollTo('top')}
          className="backtotop-button flex center"
        >
          {' '}
          Back To Top
        </div>
      )} */}
    </div>
  );
};
export default HomePage;
