import { 
  SET_USER, REMOVE_USER, 
} from '../actions/actionTypes'


const initialState = {
  user: null,
};

const userReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case SET_USER:
      let newBalance = action.payload.balance
      return {
        ...newState,
        user: action.payload,
        balance: newBalance
      };

    case REMOVE_USER:
      return {...newState, user: null, balance:null , friends: {}}


    default:
      return newState;


    }
};

export default userReducer;