import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProjectPage.css';
import { WindowContext } from '../../context/WindowContext';

const ProjectPage = ({ project }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const swipeContainerRef = useRef(null);
  const { windowWidth } = useContext(WindowContext);
  let touchStartX = 0;

  const mobileView = 900;



  const handleTouchStart = (event) => {
    // console.log('Touch Start:', event.touches[0].clientX);
    touchStartX = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    // console.log('Touch End:', event.changedTouches[0].clientX);
    const deltaX = touchStartX - event.changedTouches[0].clientX;
    // console.log('DeltaX:', deltaX);

    if (Math.abs(deltaX) > 50) {
      event.preventDefault(); // prevent default only if it's a swipe
      if (deltaX > 50) {
        setCurrentImageIndex((prev) => (prev + 1) % project.images.length);
      } else {
        setCurrentImageIndex(
          (prev) => (prev - 1 + project.images.length) % project.images.length
        );
      }
    }
  };

  const handleSelectorTouch = (index) => {
    setCurrentImageIndex(index);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % project.images.length);
  };

  const goToPrevImage = () => {
    setCurrentImageIndex(
      (prev) => (prev - 1 + project.images.length) % project.images.length
    );
  };

  useEffect(() => {
    if (!swipeContainerRef || !swipeContainerRef.current) return;
    const container = swipeContainerRef.current;

    const touchStartHandler = (event) => handleTouchStart(event);
    const touchEndHandler = (event) => handleTouchEnd(event);

    container.addEventListener('touchstart', touchStartHandler, {
      passive: false,
    });
    container.addEventListener('touchend', touchEndHandler);

    return () => {
      container.removeEventListener('touchstart', touchStartHandler);
      container.removeEventListener('touchend', touchEndHandler);
    };
  }, []);

  const navTo = (location) => {
    switch (location) {
      case 'live':
        window.open(`${project.liveUrl}`, '_blank');
        break;
      case 'git':
        window.open(`${project.gitHubUrl}`, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {windowWidth < mobileView && (
        <div className="projectpage-container flex">
          {/* <div className="projectpage-navbar-spacing"></div> */}

          <div className="projectpage-name-container flex center">
            <div className="projectpage-name memo flex center">
              {project.name}
            </div>
            <div className="links-container flex between center">
              <div
                onClick={() => {
                  navTo('live');
                }}
                className="live nav-link memo"
              >
                Live site
              </div>
              <i className="fa-solid fa-circle"></i>
              <div
                onClick={() => {
                  navTo('git');
                }}
                className="github nav-link memo"
              >
                Github
              </div>
            </div>
          </div>

          <div
            className="projectpage-image-container flex center"
            ref={swipeContainerRef}
          >

{/* mobile image sizing */}

            <div className="projectpage-image">
              <img
                src={project.images[currentImageIndex].image}
                alt="Selected project"
              />
            </div>

            <div className="projectpage-selector flex center between">
              <div
                onClick={goToPrevImage}
                className="projectpage-selector-arrow flex center"
              >
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              {project?.images.map((image, index) => (
                <div
                  className={`projectpage-selector-button flex center ${currentImageIndex === index ? ' radial-fill' : ''}`}
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  onTouchStart={() => handleSelectorTouch(index)}
                >

                </div>
              ))}
              <div
                onClick={goToNextImage}
                className="projectpage-selector-arrow flex center"
              >
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>

          <div className="projectpage-description-container flex center">
            <div className="projectpage-image-title-container memo flex">
              {project.images[currentImageIndex].title}
            </div>

            <div className="projectpage-image-description-container flex center">
              <div className="projectpage-image-description memo flex center">
                {project.images[currentImageIndex].description}
              </div>
            </div>
          </div>
        </div>
      )}

      {windowWidth >= mobileView && (
        <div className="projectpage-container wide flex">
          {/* <div className="projectpage-navbar-spacing"></div> */}



          <div className="projectpage-content flex center wide">


            <div className="projectpage-image-content wide flex center">





              <div className="projectpage-image-description-container wide flex">


            <div className="projectpage-name-container wide flex center">
            <div className="projectpage-name wide memo flex center">
              {project.name}
            </div>
            <div className="links-container flex between center">
              <div
                onClick={() => {
                  navTo('live');
                }}
                className="live nav-link memo"
              >
                Live site
              </div>
              <i className="fa-solid fa-circle"></i>
              <div
                onClick={() => {
                  navTo('git');
                }}
                className="github nav-link memo"
              >
                Github
              </div>
            </div>
          </div>




                <div className="projectpage-image-description memo wide flex center">
                {project.images[currentImageIndex].description}

                  
                </div>


                <div className="projectpage-technologies-container wide flex center">

                  {project.technologies.map((tech, index) => (
                      <img key={index} 
                          src={`https://skillicons.dev/icons?i=${tech}&perline=1`} 
                          alt={tech} />
                  ))}

                </div>



              </div>

{/* desktop image sizing */}
              <div className="projectpage-image-container wide flex center">
                  <img
                    src={project.images[currentImageIndex].image}
                    alt="Selected project"
                  />
              </div>


            </div>





            <div className="projectpage-selector wide flex center between">
              <div
                onClick={goToPrevImage}
                className="projectpage-selector-arrow flex center"
              >
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              {project?.images.map((image, index) => (
                <div
                className={`projectpage-selector-button flex center ${currentImageIndex === index ? ' radial-fill' : ''}`}
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  onTouchStart={() => handleSelectorTouch(index)}
                >
                  {currentImageIndex === index && (
                    <div className="radial-fill"></div>
                  )}
                </div>
              ))}
              <div
                onClick={goToNextImage}
                className="projectpage-selector-arrow flex center"
              >
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>






          </div>
        </div>
      )}
    </>
  );
};

export default ProjectPage;
