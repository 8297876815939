
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Route, Router, Switch, NavLink, Link,useHistory, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Navigation.css'
import { ModalContext } from '../../context/ModalContext';
import { SocketContext } from '../../context/SocketContext';
import { WindowContext } from '../../context/WindowContext';

const Navigation = ({highlite, scrollTo}) => {

  const { scrollPosition, windowHeight } = useContext(WindowContext);
  
  const [navStyle, setNavStyle] = useState('');
  
  useEffect(() => {
    // Update the state whenever scrollPosition or windowHeight changes.
    if(scrollPosition >= windowHeight){
      setNavStyle('scrolled-style')
    } else{
      setNavStyle('')
    }

    // 
  }, [scrollPosition, windowHeight]);



  return (
    <div className={`navigation-container flex center ${navStyle}`}>  
            <div onClick={()=>scrollTo('aboutme')} className={`splash-nav-item flex center ${highlite ===  'aboutme' ? ' highlite' : '' }`}>About</div>
            <div onClick={()=>scrollTo('projects')} className={`splash-nav-item flex center ${highlite === 'projects'  ? ' highlite' : '' }`}> Projects</div>
            {/* <div onClick={()=>scrollTo('resume')} className={`splash-nav-item flex center ${highlite ===  'resume' ? ' highlite' : '' }`}>Resumé</div> */}
            <div onClick={()=>scrollTo('contact')} className={`splash-nav-item flex center  ${highlite === 'contact'  ? ' highlite' : '' }`}> Contact</div>
    </div>
  )
}
export default Navigation