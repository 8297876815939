import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Route,
  Router,
  Switch,
  NavLink,
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './SplashPage.css';
import { WindowContext } from '../../context/WindowContext';

import wave from '../../assets/videos/wave.mp4';
import fish from '../../assets/videos/fish.mp4';




const SplashPage = ({scrollTo}) => {
  const videoRef = useRef(null);

  const { windowWidth, windowHeight, elementIsAtTopOfScreen, setElementIsAtTopOfScreen } = useContext(WindowContext);
  const [isAtTheTop, setIsAtTheTop] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isQuickLoaded, setIsQuickLoaded] = useState(false);

  const [videoStartedPlaying, setVideoStartedPlaying] = useState(false);


  


  useEffect(() => {
    setElementIsAtTopOfScreen(false)

    const isAtTop = (el) => {
      if(!el) return
      const rect = el.getBoundingClientRect();
      return rect.top <= 0;
    };

    const handleScroll = () => {

      const nav = document.querySelector('.splash-nav');
      if(!nav) return

      if (isAtTop(nav)) {
        setIsAtTheTop(true)
        setElementIsAtTopOfScreen(true)
        nav.classList.add('sticky');
      } else {
        setIsAtTheTop(false)
        setElementIsAtTopOfScreen(false)

        nav.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1000);
  }, [])


  useEffect(() => {
    setTimeout(() => {
      setIsQuickLoaded(true)
    }, 300);
  }, [])


  const handleVideoPlay = () => {
    setVideoStartedPlaying(true);
  };
    

 
  

useEffect(() => {
  if (videoRef && videoRef.current) {
    console.log('yes');

    videoRef.current.playbackRate = .8; // 2.0  = twice as fast
  }
}, []); 
    
  






  return (

<>


    <div className="splash-container flex center">


    <video 
            playsInline 
            autoPlay 
            muted 
            loop 
            className={`test-video hidden`}
            onPlaying={handleVideoPlay}
          >
            <source src={fish} type="video/mp4" />
            <source src={fish} type="video/webm" />
          </video>




        {isLoaded &&  videoStartedPlaying &&    
          <video 
            ref={videoRef} 
            playsInline 
            autoPlay 
            muted 
            loop 
            className={`splash-background-video fade-in-background`}
          >
            <source src={wave} type="video/mp4" />videos/
            <source src={wave} type="video/webm" />

          </video>
          }



{isLoaded &&  !videoStartedPlaying &&             
            <div className={`splash-background-image fade-in-background`}></div>
          }
         

      



        <div className={`splash-name-container flex center`}>
          <div className={`splash-text-content flex center fade-in-text ${isQuickLoaded ? '' : 'hidden'}`}>DONOVAN</div>
            <div className={`splash-nav flex center fade-in-text flex ${isAtTheTop ? ' sticky' : ''}`}>
              <div onClick={()=>scrollTo('aboutme')} className='splash-nav-item flex center'>About</div>
              <div onClick={()=>scrollTo('projects')} className='splash-nav-item flex center'> Projects</div>
              {/* <div onClick={()=>scrollTo('resume')} className='splash-nav-item flex center'>Resumé</div> */}
              <div onClick={()=>scrollTo('contact')} className='splash-nav-item flex center'> Contact</div>
            </div>
          <div className={`splash-text-content flex center fade-in-text last ${isQuickLoaded ? '' : 'hidden'}`}>CRADER</div>
        </div>
    </div>


</>



  );
};

export default SplashPage;
