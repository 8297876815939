import React, { useState } from 'react';
import './ContactPage.css';
import resumeLink from '../../assets/pdfs/Crader_Donovan_Resume.pdf';
import ContactForm from '../ContactForm';


const ContactPage = () => {


  const navTo = (location) => {
    switch(location) {
      case 'linkedin':
        window.open('https://www.linkedin.com/in/donovan-crader-898413242/', '_blank');
        break;
      case 'github':
        window.open('https://github.com/dcraderdev', '_blank');
        break;
      default:
        break;
    }
  }


  return (
    <div className="contact-container flex center">

          <div className="contact-navbar-spacing"></div>


      <div className='contact-header memo'>Drop me an email or connect via LinkedIn! I'd be glad to hear from you!</div>

      <div className='contact-icons-container flex'>


          <div className="content-icon">
            <div className="aboutmemodal-icon"  onClick={() => navTo('linkedin')}>
              <i className="fa-brands fa-linkedin"></i>
            </div>
          </div>

          <div className="content-icon" >
            <div className="aboutmemodal-icon flex" onClick={() => navTo('github')}>
              <i className="fa-brands fa-github"></i>
            </div>
          </div>


      


      </div>



     <div className="download-container">
        <a href={resumeLink} download className="download-button memo">
          Download Resume
        </a>
      </div>




    <div className="contact-form-wrapper flex center">


      <ContactForm />

    </div>

    </div>
  );
};

export default ContactPage;





        //  <img 
        //         src={resumeImg} 
        //         alt="Donovan Crader's Resume" 
        //         className="resume-image"
        //     />